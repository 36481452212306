import { ChevronDown, ChevronUp } from '@icons/index';
import { useRef, useState } from 'react';
import { Button } from './Button';

export interface CollapseProps {
  title: React.ReactNode | string;
  children: React.ReactNode;
  defaultOpen?: boolean;
  color?: 'primary' | 'secondary' | 'dark';
}

export const Collapse = ({
  title,
  children,
  defaultOpen = false,
  color = 'secondary'
}: CollapseProps) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);
  const contentRef = useRef<HTMLDivElement>(null);

  const colorClass = color ? `text-${color}` : '';

  return (
    <div className="w-full">
      <Button
        onClick={() => setIsOpen(!isOpen)}
        className={`m-0 flex w-fit items-center gap-2 border-none !bg-transparent !p-0 text-lg ${colorClass}`}
      >
        {title}{' '}
        {isOpen ? <ChevronUp className={colorClass} /> : <ChevronDown />}
      </Button>
      <div
        ref={contentRef}
        style={{
          maxHeight: isOpen ? '100vh' : 0,
          overflow: 'hidden',
          transition: `max-height 400ms ${isOpen ? 'ease-in-out' : 'cubic-bezier(0, 1, 0, 1)'}`
        }}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};
