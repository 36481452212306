import useSWR, { mutate } from 'swr';

const fetcher = async (url: string) => {
  const res = await fetch(url, { cache: 'no-store' });
  return res.text();
};

export const useMarkdownContent = (url: string) => {
  const { data, error, isLoading, mutate: refetch } = useSWR(url, fetcher);

  return {
    markdownContent: data ?? '',
    setMarkdownContent: (content: string) => mutate(url, content, false),
    isLoading,
    error,
    refetch
  };
};
