import { RoundedContainer } from '@components/RoundedContainer';
import { Link } from 'react-router-dom';
import { Button } from './Button';
import { Typography } from './Typography';

interface PopupCTAProps {
  title: string;
  buttonText: string;
  link: string;
}

export const PopupCTA = ({ title, buttonText, link }: PopupCTAProps) => {
  return (
    <div
      className="absolute left-0 top-0 flex h-full w-full items-center justify-center"
      data-testid="popup-cta"
    >
      <RoundedContainer className="animate-fade-in bg-white p-[40px] text-center">
        <Typography variant="h2" className="mb-[40px] text-dark">
          {title}
        </Typography>
        <Link to={link}>
          <Button variant="secondary">{buttonText}</Button>
        </Link>
      </RoundedContainer>
    </div>
  );
};
