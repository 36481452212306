interface CardProps {
  className?: string;
  children?: React.ReactNode;
}
export const Card = ({ children, className }: CardProps) => {
  return (
    <div
      data-testid="card"
      className={`rounded-[0px_20px_20px_20px] bg-white p-[40px] shadow-[0px_4px_12px_0px__rgba(0,0,0,0.05)] ${className}`}
    >
      {children}
    </div>
  );
};
