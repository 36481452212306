import { MarkdownViewer } from '@elements/MarkdownViewer';
import { QuickAction } from '@models/chatMessage';
import { Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import loadComponents from './LoadQuickActions';
import ErrorQuickAction from './quick-actions/Error';
interface ChatBubbleProps {
  message: string;
  messageId: string;
  quickActions?: QuickAction[];
  isUser: boolean;
  isLast?: boolean;
  sendMessage?: (message: string) => void;
  translatable: boolean;
}

const ChatBubble = ({
  message,
  isUser,
  quickActions,
  messageId,
  isLast,
  sendMessage,
  translatable
}: ChatBubbleProps) => {
  const [components, setComponents] = useState<any[]>([]);
  const { t } = useTranslation('chat');

  useEffect(() => {
    if (quickActions) {
      loadComponents(quickActions, messageId, sendMessage).then(setComponents);
    }
  }, [quickActions, sendMessage]);

  const renderMessage = (message: string) => {
    if (translatable) {
      message = t(message);
    }
    return (
      <div className={`flex ${isUser && 'justify-end'}`}>
        <div className={getBubbleClass(isUser)}>
          <MarkdownViewer content={message} />
        </div>
      </div>
    );
  };

  const renderQuickActions = () => {
    return (
      <Suspense fallback={<ErrorQuickAction />}>
        <div className="justify-left mt-2 flex max-w-[80%]">
          {components.map((component, index) => (
            <div
              key={`quick-action-${index}`}
              className="max-w-fit flex-1 rounded-[20px] bg-[#fff] p-[16px_24px] text-sm tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]"
            >
              {component}
            </div>
          ))}
        </div>
      </Suspense>
    );
  };

  const getBubbleClass = (isUser: boolean) => {
    let bubbleClass =
      'max-w-[80%] rounded-[20px] p-[16px_24px] tracking-[0.6px] shadow-[2px_4px_4px_0px_rgba(0,0,0,0.08)]';
    return isUser ? `${bubbleClass} bg-[#DBE2FD] ` : `${bubbleClass} bg-[#fff]`;
  };

  return (
    <div>
      {message !== '/' && renderMessage(message)}
      {components.length > 0 && isLast && renderQuickActions()}
    </div>
  );
};

export default ChatBubble;
