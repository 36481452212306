import { Vacancy } from '@models/Vacancy';
import { useEffect, useState } from 'react';
import { io, Socket } from 'socket.io-client';
import useSWR from 'swr';
import { useMyVacancies } from './use-my-vacancies';

export const useVacancy = (id: string) => {
  const { data, ...swr } = useSWR(`/vacancies/${id}`);
  const { mutate: mutateIndex } = useMyVacancies();
  const [socket, setSocket] = useState<Socket | null>(null);
  let vacancy = null;
  if (data) {
    vacancy = Vacancy.fromJson(data);
  }

  useEffect(() => {
    if (vacancy && !socket) {
      const newSocket = io(import.meta.env.VITE_API_URL);
      newSocket.emit('join', 'vacancy-updates-' + vacancy.id);
      newSocket.on('connection', socket => {
        socket.join('vacancy-updates-' + vacancy.id);
      });
      newSocket.on('message', () => {
        swr.mutate();
        mutateIndex();
      });
      setSocket(newSocket);
    }
  }, [vacancy]);

  return {
    vacancy,
    ...swr
  };
};
