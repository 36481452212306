import { Address } from '@models/address';
import { useAddressSearch } from '@services/account/use-address-search';
import { Colors } from '@utils/ColorUtils';
import { useEffect, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { IInput, Input } from './Input';

interface IPlaceInput {
  inputProps?: IInput;
  defaultValue?: Address;
  onSelect?: (address: Address) => void;
}

const createAddressText = (address: Address) => {
  let _textValue = address.street ? `${address.street}, ` : '';
  _textValue +=
    address.city && address.city !== 'null' ? `${address.city}, ` : '';
  _textValue += address.country && `${address.country}`;
  return _textValue;
};

export const PlaceInput = ({
  inputProps,
  defaultValue,
  onSelect
}: IPlaceInput) => {
  const [inputTimeout, setInputTimeout] = useState<NodeJS.Timeout | null>(null);
  const [textValue, setTextValue] = useState('');
  const [isWaiting, setIsWaiting] = useState(false);
  useEffect(() => {
    if (defaultValue && textValue === '') {
      const _textValue = createAddressText(defaultValue);
      setTextValue(_textValue);
    }
  }, [defaultValue]);

  const { setValue, suggestions, clearSuggestions, isLoading } =
    useAddressSearch();

  const handleSelect = async (suggestion: Address) => {
    clearSuggestions();
    setTextValue(createAddressText(suggestion));
    onSelect?.(suggestion);
  };

  return (
    <div className="relative">
      <Input
        value={textValue}
        onChange={e => {
          setIsWaiting(true);
          if (inputTimeout) {
            clearTimeout(inputTimeout);
          }
          setInputTimeout(
            setTimeout(() => {
              setValue(e.target.value);
              setIsWaiting(false);
            }, 500)
          );
        }}
        {...inputProps}
      />
      {(isLoading || isWaiting) && (
        <div className="absolute right-[10px] top-[15px]">
          <ClipLoader size={20} color={Colors.primary} />
        </div>
      )}
      {suggestions.length > 0 && (
        <div className="absolute left-[-5px] top-[40px] flex w-full flex-col gap-3 rounded-[20px] bg-white p-[16px_24px] shadow-[0px_4px_8px_0px_rgba(0,0,0,0.08),0px_-2px_8px_0px_rgba(0,0,0,0.08)]">
          {suggestions.map((suggestion, index) => {
            return (
              <div
                key={'suggestion-' + index}
                className="hover:bg-gray-100 rounded-[8px] p-2 hover:cursor-pointer"
                onClick={async () => {
                  handleSelect(suggestion);
                }}
              >
                {createAddressText(suggestion)}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
