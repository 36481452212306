import { Button } from '@elements/Button';
import { Popup } from '@elements/Popup';
import { Typography } from '@elements/Typography';
import React, { createContext, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

interface ValidationPopupContextType {
  show: (message?: string, callback?: () => void) => void;
  hide: () => void;
}

const ValidationPopupContext = createContext<
  ValidationPopupContextType | undefined
>(undefined);

export const ValidationPopupProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState<string>();
  const [pendingCallback, setPendingCallback] = React.useState<
    (() => void) | undefined
  >();
  const { t } = useTranslation('generic');

  const show = useCallback((message?: string, callback?: () => void) => {
    setIsOpen(true);
    setPopupMessage(message);
    setPendingCallback(() => callback);
  }, []);

  const hide = useCallback(() => {
    setIsOpen(false);
    setPendingCallback(undefined);
  }, []);

  const handleConfirm = useCallback(() => {
    if (pendingCallback) {
      pendingCallback();
    }
    hide();
  }, [pendingCallback, hide]);

  return (
    <ValidationPopupContext.Provider value={{ show, hide }}>
      {children}
      <Popup isOpen={isOpen} closeButton={false}>
        <div className="flex flex-col gap-4">
          <Typography variant="h3">
            {popupMessage ?? t('common:areYouSure')}
          </Typography>
          <div className="flex justify-center gap-4">
            <Button variant="info" onClick={hide}>
              {t('cancel')}
            </Button>
            <Button variant="primary" onClick={handleConfirm}>
              {t('confirm')}
            </Button>
          </div>
        </div>
      </Popup>
    </ValidationPopupContext.Provider>
  );
};

export const useValidationPopup = () => {
  const context = useContext(ValidationPopupContext);
  if (!context) {
    throw new Error(
      'useValidationPopup must be used within a ValidationPopupProvider'
    );
  }
  return context;
};
