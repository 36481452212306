import {
  DashboardNotifications,
  DashboardRefer,
  DashboardVacancies
} from '@components/dashboard';
import { Container } from '@elements/Container';
import { Typography } from '@elements/Typography';
import { Employer } from '@models/Employer';
import { useAccountContext } from '@providers/UserContext';
import { useTranslation } from 'react-i18next';

export const DashboardPage = () => {
  const { user } = useAccountContext<Employer>();
  const { t } = useTranslation('dashboard');
  return (
    <Container className="flex flex-col gap-4 p-6">
      <Typography variant="h1" color="primary" className="mb-4">
        {t('greetings', { name: user?.accountInfo.firstName })}
      </Typography>
      <div className="grid grid-cols-[2fr_1fr] gap-4">
        <DashboardNotifications className="min-h-[400px]" />
        <DashboardRefer />
      </div>
      <div className="mt-8">
        <DashboardVacancies />
      </div>
    </Container>
  );
};
