import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';
import { useLocation } from 'react-router-dom';

interface BackgroundContextType {
  setBackgroundEnabled: (enabled: boolean) => void;
}

const BackgroundContext = createContext<BackgroundContextType | undefined>(
  undefined
);

interface BackgroundProviderProps {
  children: ReactNode;
}

export const BackgroundProvider = ({ children }: BackgroundProviderProps) => {
  const [isEnabled, setIsEnabled] = useState(true);
  const location = useLocation();

  // Reset background when route changes
  useEffect(() => {
    setIsEnabled(true);
  }, [location.pathname]);

  return (
    <BackgroundContext.Provider value={{ setBackgroundEnabled: setIsEnabled }}>
      {children}
      {isEnabled && (
        <div className="absolute -z-20 h-full w-full">
          <div className="absolute bottom-0 left-0 h-[350px] w-[70%] rounded-[0px_100px_0px_0px] bg-[#DBE2FD]"></div>
          <div className="absolute right-0 top-[-20px] h-[400px] w-[70%] rounded-[0px_0px_0px_100px] bg-[#ECECE3]"></div>
        </div>
      )}
    </BackgroundContext.Provider>
  );
};

export const useBackground = (enabled: boolean) => {
  const context = useContext(BackgroundContext);
  if (!context) {
    throw new Error('useBackground must be used within a BackgroundProvider');
  }

  useEffect(() => {
    context.setBackgroundEnabled(enabled);
  }, [enabled, context]);
};
