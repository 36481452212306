import { ProgressCircle } from '@components/profile/ProgressCircle';
import { Card } from '@elements/Card';
import { Divider } from '@elements/Divider';
import { Typography } from '@elements/Typography';
import { Vacancy } from '@models/Vacancy';
import { Colors } from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface VacancyCardProps {
  vacancy: Vacancy;
}

export const VacancyCard = ({ vacancy }: VacancyCardProps) => {
  const { t } = useTranslation('vacancies');
  return (
    <Link
      to={`/vacancies/${vacancy.id}`}
      className="no-underline hover:animate-raise"
    >
      <Card className="h-full !p-6">
        <Typography
          variant="h5"
          className={`line-clamp-1 text-base ${vacancy.title ? '' : 'italic'}`}
        >
          {vacancy.title ?? t('draft')}
        </Typography>
        <Divider className="my-4" />
        <div className="grid min-h-[68px] grid-cols-[3fr_1fr]">
          <div className="flex flex-col justify-center gap-2">
            <Typography variant="body1" className="text-sm">
              {t('created_at')}:{' '}
              {vacancy.createdAt?.toLocaleDateString('nl-NL', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric'
              })}
            </Typography>
            <Typography variant="body1" className="text-sm text-secondary">
              {vacancy.intakeProgress === 1
                ? t('x_potential_candidates', {
                    count: 0
                  })
                : t('complete_to_see_candidates')}
            </Typography>
          </div>
          {vacancy.intakeProgress !== undefined &&
            vacancy.intakeProgress < 1 && (
              <div className="flex items-center justify-end">
                <ProgressCircle
                  percentage={
                    vacancy.intakeProgress ? vacancy.intakeProgress * 100 : 0
                  }
                  color={Colors.secondary}
                  size="sm"
                  hideButton
                />
              </div>
            )}
        </div>
      </Card>
    </Link>
  );
};
