import { BackArrow } from '@icons/index';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface BackButtonProps {
  className?: string;
  iconClassName?: string;
  onClick?: () => void;
  hideText?: boolean;
}

export const BackButton = ({
  className,
  onClick,
  hideText,
  iconClassName
}: BackButtonProps) => {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigate(-1);
    }
  };

  return (
    <button
      onClick={handleClick}
      className={`flex items-center gap-2 text-xl font-semibold underline ${className}`}
    >
      <BackArrow className={`h-5 w-5 ${iconClassName}`} />{' '}
      {!hideText && t('back')}
    </button>
  );
};
