import PlaceholderCard from '@assets/placeholder-card.svg';
import { VacancyCard } from '@components/vacancies/VacancyCard';
import { Button } from '@elements/Button';
import { Container } from '@elements/Container';
import { PopupCTA } from '@elements/PopupCTA';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Employer } from '@models/Employer';
import { useMyVacancies } from '@services/vacancies/use-my-vacancies';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const VacanciesPage = () => {
  const { t } = useTranslation('vacancies');
  const { user } = useAccountContext<Employer>();
  const { vacancies, isLoading } = useMyVacancies();
  const isMobile = useIsMobile();

  const PlaceholderCards = () => (
    <>
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
      {user?.accountInfo?.intakeProgress === 100 ? (
        <PopupCTA
          title={t('create_first_vacancy')}
          buttonText={t('create_vacancy')}
          link="/vacancies/create"
        />
      ) : (
        <PopupCTA
          title={t('intake_incomplete')}
          buttonText={t('go_to_intake')}
          link="/intake"
        />
      )}
    </>
  );

  return (
    <>
      <Container className={`${!isMobile && 'py-10'} relative`}>
        <div className="flex items-center justify-between">
          <Typography variant="h1">{t('vacancies')}</Typography>
          {vacancies && vacancies.length > 0 && (
            <Button variant="secondary" link="/vacancies/create">
              {t('create_new')}
            </Button>
          )}
        </div>
        <div className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3">
          {isLoading || vacancies.length === 0 ? (
            <PlaceholderCards />
          ) : (
            vacancies.map(vacancy => (
              <VacancyCard key={vacancy.id} vacancy={vacancy} />
            ))
          )}
        </div>
      </Container>
    </>
  );
};
