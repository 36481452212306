import { ChevronLeft, ChevronRight } from '@icons/index';
import { useEffect, useState } from 'react';

type CalendarProps = {
  locale?: string;
  defaultValue?: Date;
  onChange?: (date: Date) => void;
};

export const Calendar: React.FC<CalendarProps> = ({
  locale = 'en-US',
  defaultValue,
  onChange,
  ...props
}) => {
  const initialDate = defaultValue || new Date();
  const [date, setDate] = useState(initialDate);
  const [days, setDays] = useState<(string | Date)[]>([]);
  const [selectedDate, setSelectedDate] = useState(
    defaultValue?.toDateString() || ''
  );

  const [year, setYear] = useState(initialDate.getFullYear());
  const [month, setMonth] = useState(initialDate.getMonth());

  useEffect(() => {
    displayCalendar();
  }, [year, month]);

  useEffect(() => {
    if (!defaultValue) return;
    if (defaultValue.toDateString() === selectedDate) return;
    setSelectedDate(defaultValue.toDateString());
    setDate(defaultValue);
    setYear(defaultValue.getFullYear());
    setMonth(defaultValue.getMonth());
  }, [defaultValue]);

  const displayCalendar = () => {
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    const firstDayIndex = (firstDay.getDay() + 6) % 7; // Adjust index to start from Monday
    const numberOfDays = lastDay.getDate();
    const tempDays = [];

    // Add empty days to align the first day of the month correctly
    for (let x = 0; x < firstDayIndex; x++) {
      tempDays.push('');
    }

    // Add days of the month
    for (let i = 1; i <= numberOfDays; i++) {
      let currentDate = new Date(year, month, i);
      tempDays.push(currentDate);
    }

    setDays(tempDays);
  };

  const handlePrevMonth = () => {
    if (month === 0) {
      setYear(year - 1);
      setMonth(11);
    } else {
      setMonth(month - 1);
    }
    setDate(new Date(year, month - 1));
    setSelectedDate('');
  };

  const handleNextMonth = () => {
    if (month === 11) {
      setYear(year + 1);
      setMonth(0);
    } else {
      setMonth(month + 1);
    }
    setDate(new Date(year, month + 1));
    setSelectedDate('');
  };

  const handlePrevYear = () => {
    setYear(year - 1);
    setDate(new Date(year - 1, month));
    setSelectedDate('');
  };

  const handleNextYear = () => {
    setYear(year + 1);
    setDate(new Date(year + 1, month));
    setSelectedDate('');
  };

  const handleDayClick = (day: Date) => {
    if (day) {
      setSelectedDate(day.toDateString());
      onChange && onChange(day);
    }
  };

  const formattedMonth = date.toLocaleString(locale, {
    month: 'long'
    // year: 'numeric'
  });
  const formattedYear = date.toLocaleString(locale, {
    year: 'numeric'
  });

  // Get day names based on the locale, starting from Monday
  const dayNames = Array.from({ length: 7 }).map((_, index) => {
    const day = new Date(2021, 0, index + 4); // Start from Monday, 4th January 2021
    return day.toLocaleDateString(locale, { weekday: 'short' });
  });

  return (
    <div
      className="bg-gray-100 flex flex-col items-center justify-center"
      {...props}
    >
      <div className="rounded-xl bg-white p-4 shadow-lg">
        <header>
          <div className="mb-4 flex items-center justify-between">
            <button
              onClick={handlePrevYear}
              data-testid="prev-year"
              className="px-2 text-xl text-secondary"
            >
              <ChevronLeft />
            </button>
            <div className="text-lg font-semibold text-secondary">
              {formattedYear}
            </div>
            <button
              onClick={handleNextYear}
              data-testid="next-year"
              className="px-2 text-xl text-secondary"
            >
              <ChevronRight />
            </button>
          </div>
          <div className="mb-4 flex items-center justify-between">
            <button
              onClick={handlePrevMonth}
              data-testid="prev-month"
              className="px-2 text-xl text-secondary"
            >
              <ChevronLeft />
            </button>
            <div className="text-lg font-semibold text-secondary">
              {formattedMonth}
            </div>
            <button
              onClick={handleNextMonth}
              data-testid="next-month"
              className="px-2 text-xl text-secondary"
            >
              <ChevronRight />
            </button>
          </div>
        </header>

        <div className="text-gray-500 mb-2 grid grid-cols-7 gap-2 text-center">
          {dayNames.map((dayName, index) => (
            <div key={index}>{dayName}</div>
          ))}
        </div>

        <div className="grid grid-cols-7 gap-2">
          {days.map((day, index) => (
            <div
              key={index}
              className={`flex h-12 w-12 cursor-pointer items-center justify-center rounded-full ${
                day ? 'hover:bg-blue-100' : ''
              } ${
                day &&
                day instanceof Date &&
                day.toDateString() === selectedDate
                  ? 'bg-secondary text-white'
                  : ''
              }`}
              onClick={() => {
                const isDate = day instanceof Date;
                if (!isDate) return;
                handleDayClick(day);
              }}
            >
              {day && day instanceof Date && day.getDate()}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
