import { ChangeEvent, useEffect, useState } from 'react';
import { IInput, Input } from './Input';

export const PhoneInput = (inputProps: IInput) => {
  const [error, setError] = useState<string | undefined>();
  const [localValue, setLocalValue] = useState<string>(
    (inputProps.value as string) || ''
  );

  const validatePhone = (value: string) => {
    const phoneRegex = /^(?:\+|00)?\d{1,3}[1-9]\d{7,12}$/;
    return value ? phoneRegex.test(value) : true; // Allow empty value unless required
  };

  useEffect(() => {
    setLocalValue((inputProps.value as string) || '');
  }, [inputProps.value]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    setLocalValue(newValue);

    const isValid = validatePhone(newValue);
    setError(
      isValid ? undefined : 'Please enter a valid international phone number'
    );

    // Only call parent onChange if the number is valid or empty
    if (isValid && inputProps.onChange) {
      inputProps.onChange(e);
    }
  };

  return (
    <Input
      {...inputProps}
      value={localValue}
      onChange={handleChange}
      error={error || inputProps.error}
      placeholder={inputProps.placeholder}
    />
  );
};
