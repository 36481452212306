import PlaceholderCard from '@assets/placeholder-card.svg';
import { Container } from '@elements/Container';
import { PopupCTA } from '@elements/PopupCTA';
import { Typography } from '@elements/Typography';
import useIsMobile from '@hooks/is-mobile';
import { Candidate } from '@models/candidate';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const RecommendedJobsPage = () => {
  const { t } = useTranslation('recommended_jobs');
  const { user } = useAccountContext<Candidate>();
  const isMobile = useIsMobile();

  const PlaceholderCards = () => (
    <>
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
      <img src={PlaceholderCard} className="w-full" />
    </>
  );

  return (
    <>
      <Container className={`${!isMobile && 'py-10'} relative`}>
        <div className="flex flex-col justify-between gap-6 pb-4">
          <Typography variant="h1">{t('title')}</Typography>
          <Typography variant="body1">{t('subtitle')}</Typography>
        </div>
        <div className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3">
          <PlaceholderCards />
        </div>
        {user?.accountInfo?.intakeProgress !== 100 && (
          <PopupCTA
            title={t('intake_incomplete')}
            buttonText={t('go_to_intake')}
            link="/intake"
          />
        )}
      </Container>
    </>
  );
};
