import { Colors } from '@utils/ColorUtils';
import { ClipLoader } from 'react-spinners';

export const LoadingPage = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <ClipLoader color={Colors.primary} size={50} />
    </div>
  );
};
