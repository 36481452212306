import { useToast } from '@elements/Toast';
import { Address } from '@models/address';
import { Candidate } from '@models/candidate';
import { KoraApi, objectToFormData } from '@services/api';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccountContext } from 'src/providers/UserContext';

export const useCandidatePatch = (info: {
  email?: string;
  firstName?: string;
  lastName?: string;
  birthDate?: Date;
  description?: string;
  phone?: string;
  address?: Address;
  profilePicture?: File;
}) => {
  const { user, mutate } = useAccountContext();
  const { t } = useTranslation('profile');
  const { success, error } = useToast();
  const [isSaving, setIsSaving] = useState(false);
  const patch = async () => {
    setIsSaving(true);
    try {
      const jsonData = { ...info, birthDate: info.birthDate?.toISOString() };
      const formData = objectToFormData(jsonData);

      await KoraApi.patch('/account', formData);
      mutate();

      success(t('update_success'));
    } catch (e) {
      error(t('update_failed'));
    }
    setIsSaving(false);
  };

  return useMemo(() => {
    const accountInfo = user?.accountInfo
      ? (user.accountInfo as Candidate)
      : null;

    const canSave =
      info.email !== user?.email ||
      info.firstName !== user?.accountInfo?.firstName ||
      info.lastName !== user?.accountInfo?.lastName ||
      info.birthDate?.toDateString() !==
        accountInfo?.birthDate?.toDateString() ||
      info.address !== user?.accountInfo?.address ||
      info.phone !== user?.accountInfo?.phone ||
      info.profilePicture;

    const isComplete =
      (info.email || user?.email) &&
      (info.firstName || user?.accountInfo?.firstName) &&
      (info.lastName || user?.accountInfo?.lastName) &&
      (info.birthDate || accountInfo?.birthDate) &&
      (info.address || accountInfo?.address);

    return { patch, canSave, isComplete, isSaving };
  }, [info, isSaving, user]);
};
