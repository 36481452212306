import { Address } from './address';
import { Employer } from './Employer';

export class Vacancy {
  constructor(
    public id: string,
    public employer?: Employer,
    public title?: string,
    public description?: string,
    public salaryMin?: number,
    public salaryMax?: number,
    public benefits?: string[],
    public originalUrl?: string,
    public requiredSkills?: string[],
    public responsibilities?: string[],
    public homeworkDays?: number,
    public address?: Address,
    public intakeProgress?: number,
    public createdAt?: Date,
    public summaryUrl?: string
  ) {}

  static fromJson(json: any): Vacancy {
    const employer = json.employer
      ? Employer.fromJson(json.employer)
      : undefined;
    return new Vacancy(
      json.id,
      employer,
      json.title,
      json.description,
      json.salaryMin,
      json.salaryMax,
      json.benefits,
      json.originalUrl,
      json.requiredSkills,
      json.responsibilities,
      json.homeworkDays,
      json.address ? Address.fromJson(json.address) : undefined,
      json.intakeProgress,
      json.createdAt ? new Date(json.createdAt) : undefined,
      json.summaryUrl
    );
  }
}
