import { Vacancy } from '@models/Vacancy';
import useSWR from 'swr';

export const useMyVacancies = (pagination?: {
  page?: number;
  pageSize?: number;
}) => {
  let url = pagination
    ? `/account/vacancies?page=${pagination.page ?? 1}&pageSize=${
        pagination.pageSize ?? 10
      }`
    : '/account/vacancies';

  console.log(url);
  const { data, ...swrProps } = useSWR(url);
  if (!data) return { vacancies: [], ...swrProps };

  const vacancies: Vacancy[] = data?.map(Vacancy.fromJson) ?? [];
  return {
    vacancies,
    ...swrProps
  };
};
