import { Collapse } from '@elements/Collapse';
import { Typography } from '@elements/Typography';
import { Vacancy } from '@models/Vacancy';
import { useTranslation } from 'react-i18next';

export const VacancyDraft = ({
  vacancy,
  defaultOpen = false,
  hideTitle = false
}: {
  vacancy: Omit<Vacancy, 'id'>;
  defaultOpen?: boolean;
  hideTitle?: boolean;
}) => {
  const { t } = useTranslation('vacancies');
  return (
    <>
      {!hideTitle && vacancy.title && <VacancyTitle title={vacancy.title} />}
      {vacancy.description && (
        <VacancyDescription description={vacancy.description} />
      )}

      <div className="flex w-full justify-between">
        {(vacancy.salaryMax || vacancy.salaryMin) && (
          <SalaryRange min={vacancy.salaryMin} max={vacancy.salaryMax} />
        )}
        {vacancy.homeworkDays && <HomeworkDays days={vacancy.homeworkDays} />}
      </div>

      {vacancy.benefits && (
        <TitledList
          title={t('benefits')}
          items={vacancy.benefits}
          defaultOpen={defaultOpen}
        />
      )}
      {vacancy.requiredSkills && (
        <TitledList
          title={t('required_skills')}
          items={vacancy.requiredSkills}
          defaultOpen={defaultOpen}
        />
      )}
      {vacancy.responsibilities && (
        <TitledList
          title={t('responsibilities')}
          items={vacancy.responsibilities}
          defaultOpen={defaultOpen}
        />
      )}
    </>
  );
};

const VacancyTitle = ({ title }: { title: string }) => (
  <Typography variant="h5" className="text-xl text-primary">
    {title}
  </Typography>
);

const VacancyDescription = ({ description }: { description: string }) => (
  <Typography variant="body1">{description}</Typography>
);

const SalaryRange = ({ min, max }: { min?: number; max?: number }) => {
  const { t } = useTranslation('vacancies');

  return (
    <div className="flex gap-2">
      <Typography variant="body1" className="font-bold">
        {t('salary_range')}:
      </Typography>
      {min && <Typography variant="body1">€{min}</Typography>}
      {max && min && <Typography variant="body1">-</Typography>}
      {max && <Typography variant="body1">€{max}</Typography>}
    </div>
  );
};

const TitledList = ({
  title,
  items,
  defaultOpen = false
}: {
  title: string;
  items: string[];
  defaultOpen?: boolean;
}) => {
  if (items.length === 0) return null;
  return (
    <div className="mb-4 flex flex-col gap-2">
      <Collapse
        title={<Typography variant="h5">{title}</Typography>}
        defaultOpen={defaultOpen}
      >
        <ul className="ml-4 list-disc">
          {items?.map(item => (
            <li key={item}>
              <Typography variant="body1">{item}</Typography>
            </li>
          ))}
        </ul>
      </Collapse>
    </div>
  );
};

const HomeworkDays = ({ days }: { days: number }) => {
  const { t } = useTranslation('vacancies');

  return (
    <div className="flex gap-2">
      <Typography variant="body1" className="font-bold">
        {t('homework_days')}:
      </Typography>
      <Typography variant="body1">{days}</Typography>
    </div>
  );
};
