import { RoundedContainer } from '@components/RoundedContainer';
import { Button } from '@mdxeditor/editor';
import { useEffect, useState } from 'react';

interface PopupProps {
  children: React.ReactNode;
  isOpen: boolean;
  closeButton?: boolean;
  onClose?: () => void;
}

export const Popup = ({
  children,
  isOpen: propsIsOpen,
  closeButton = true,
  onClose
}: PopupProps) => {
  const [isOpen, setIsOpen] = useState(propsIsOpen);

  useEffect(() => {
    setIsOpen(propsIsOpen);
  }, [propsIsOpen]);

  const handleClose = () => {
    setIsOpen(false);
    onClose?.();
  };

  return (
    <>
      {isOpen && (
        <div
          className="fixed left-0 top-0 z-50 flex h-full w-full items-center justify-center bg-black/50"
          data-testid="popup-cta"
        >
          <RoundedContainer className="animate-fade-in bg-white p-[40px] text-center">
            {closeButton && <Button onClick={handleClose}>Close</Button>}
            {children}
          </RoundedContainer>
        </div>
      )}
    </>
  );
};
