import * as Sentry from '@sentry/react';
import './App.css';
import { Header } from './Header';
import './i18n';
import { BackgroundProvider } from './providers/BackgroundProvider';
import { KoraRoutes } from './routes';

function App() {
  return (
    <div className="flex h-[100vh] flex-col">
      <Header />
      <div className="relative mt-[88px] flex w-full flex-1">
        <BackgroundProvider>
          <KoraRoutes />
        </BackgroundProvider>
      </div>
    </div>
  );
}

export default Sentry.withProfiler(App);
