import { Tooltip } from '@elements/Tooltip';
import { Colors } from '@utils/ColorUtils';

interface ITypography {
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'smallTitle'
    | 'body1'
    | 'body2'
    | 'caption';
  children: React.ReactNode;
  className?: string;
  enableTooltip?: boolean;
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
  color?: keyof typeof Colors;
}

export const Typography = ({
  variant = 'body1',
  className,
  children,
  enableTooltip,
  tooltipPlacement = 'bottom',
  color = 'default'
}: ITypography) => {
  const content = typeof children === 'string' ? children : null;

  const colorClasses = {
    primary: 'text-primary',
    secondary: 'text-secondary',
    error: 'text-error',
    success: 'text-success',
    warning: 'text-warning',
    dark: 'text-dark',
    default: ''
  };

  const combinedClassName =
    `${colorClasses[color as keyof typeof colorClasses]}  ${className || ''}`.trim();

  const renderContent = (element: JSX.Element) => {
    if (!enableTooltip || !content) {
      return element;
    }

    return (
      <Tooltip content={content} placement={tooltipPlacement}>
        {element}
      </Tooltip>
    );
  };

  if (variant === 'h1')
    return renderContent(
      <h1
        className={`font-semibold sm:text-[2rem] md:text-[2.5rem] ${combinedClassName}`}
      >
        {children}
      </h1>
    );
  if (variant === 'h2')
    return renderContent(
      <h2 className={`text-3xl font-semibold ${combinedClassName}`}>
        {children}
      </h2>
    );

  if (variant === 'h3')
    return renderContent(
      <h3 className={`text-[1.75rem] font-semibold ${combinedClassName}`}>
        {children}
      </h3>
    );

  if (variant === 'h4')
    return renderContent(
      <h4 className={`text-[1.5rem] font-semibold ${combinedClassName}`}>
        {children}
      </h4>
    );

  if (variant === 'h5')
    return renderContent(
      <h5 className={`text-[1.25rem] font-semibold ${combinedClassName}`}>
        {children}
      </h5>
    );

  if (variant === 'smallTitle')
    return renderContent(
      <p className={`text-normal font-semibold ${combinedClassName}`}>
        {children}
      </p>
    );

  if (variant === 'caption')
    return renderContent(
      <p className={`text-sm ${combinedClassName}`}>{children}</p>
    );
  if (variant === 'body2')
    return renderContent(
      <p className={`text-xs ${combinedClassName}`}>{children}</p>
    );
  return renderContent(
    <p className={`text-${variant} ${combinedClassName}`}>{children}</p>
  );
};
