import { ChangeEvent, useEffect, useState } from 'react';
import { IInput, Input } from './Input';

export const EmailInput = (inputProps: IInput) => {
  const [error, setError] = useState<string | undefined>();
  const [localValue, setLocalValue] = useState<string>(
    (inputProps.value as string) || ''
  );

  const validateEmail = (value: string) => {
    const emailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return value ? emailRegex.test(value) : true; // Allow empty value unless required
  };

  useEffect(() => {
    setLocalValue((inputProps.value as string) || '');
  }, [inputProps.value]);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const newValue = e.target.value;
    setLocalValue(newValue);

    const isValid = validateEmail(newValue);
    setError(isValid ? undefined : 'Please enter a valid email address');

    // Only call parent onChange if the email is valid or empty
    if (isValid && inputProps.onChange) {
      inputProps.onChange(e);
    }
  };

  return (
    <div>
      <Input
        {...inputProps}
        type="email"
        value={localValue}
        onChange={handleChange}
        error={error || inputProps.error}
        placeholder={inputProps.placeholder}
      />
    </div>
  );
};
