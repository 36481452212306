import { Button } from '@elements/Button';
import { Input } from '@elements/Input';
import { Attachment, SendArrow } from '@icons/index';
import { useState } from 'react';

export interface ChatInputProps {
  isAnswering: boolean;
  onSend: (message: string) => void;
  inputRef?: React.RefObject<HTMLInputElement>;
}

export const ChatInput = ({
  isAnswering,
  onSend,
  inputRef
}: ChatInputProps) => {
  const [message, setMessage] = useState('');

  return (
    <div className="flex-[0_0] pb-10">
      <Input
        disabled={isAnswering}
        className="!rounded-[20px] !border-[#000] !bg-white !p-6 !px-[55px] text-sm"
        placeholder="Reply to Kora"
        onChange={e => setMessage(e.target.value)}
        value={message}
        ref={inputRef}
        onKeyDown={e => {
          if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            onSend(message);
            setMessage('');
          }
        }}
        startAdornment={
          <Button className="border-none !bg-transparent">
            <Attachment />
          </Button>
        }
        endAdornment={
          <Button
            className="border-none !bg-transparent"
            onClick={() => onSend(message)}
          >
            <SendArrow />
          </Button>
        }
        autoGrow
      />
    </div>
  );
};
