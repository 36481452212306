import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useState(
    new URLSearchParams(location.search)
  );

  useEffect(() => {
    setSearchParams(new URLSearchParams(location.search));
  }, [location.search]);

  return useMemo(() => {
    const result: { [key: string]: string } = {};
    for (const key of searchParams.keys()) {
      result[key] = searchParams.get(key) ?? '';
    }
    return result;
  }, [searchParams]);
};
