import React, {
  ChangeEvent,
  FocusEvent,
  forwardRef,
  KeyboardEvent,
  useEffect,
  useRef,
  useState
} from 'react';
import { Typography } from './Typography';

export type InputVariant = 'default' | 'compact';

export interface IInput
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  label?: string;
  defaultValue?: string;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  allowedChars?: string[];
  onChange?: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onKeyDown?: (
    e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  variant?: InputVariant;
  endAdornment?: React.ReactNode;
  startAdornment?: React.ReactNode;
  error?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  autoGrow?: boolean;
  'data-testid'?: string;
}

export const Input = forwardRef<HTMLInputElement, IInput>(
  (
    {
      label,
      className = '',
      type = 'text',
      onChange,
      disabled,
      value: propsValue,
      defaultValue = '',
      variant = 'default',
      allowedChars,
      placeholder,
      onFocus,
      onBlur,
      endAdornment,
      startAdornment,
      error,
      onKeyDown,
      inputRef,
      autoGrow,
      'data-testid': dataTestId
    },
    ref
  ) => {
    const [value, setValue] = useState(defaultValue);
    const [isFocused, setIsFocused] = useState(false);
    const textareaRef = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
      if (propsValue !== undefined) {
        setValue(propsValue.toString());
      }
    }, [propsValue]);

    useEffect(() => {
      if (autoGrow && textareaRef.current) {
        textareaRef.current.style.height = 'auto';
        textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
      }
    }, [value, autoGrow]);

    const mustMoveLabel = isFocused || value.length > 0;

    const handleChange = (
      e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      if (allowedChars) {
        const regex = new RegExp(`^[${allowedChars.join('')}]*$`);
        if (!regex.test(e.target.value)) return;
      }
      setValue(e.target.value);
      onChange?.(e);
    };

    const handleFocus = (
      e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setIsFocused(true);
      onFocus?.(e as FocusEvent<HTMLInputElement>);
    };

    const handleBlur = (
      e: FocusEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      setIsFocused(false);
      onBlur?.(e as FocusEvent<HTMLInputElement>);
    };

    const handleKeyDown = (
      e: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
      onKeyDown?.(e);
    };

    const inputClassName = `${
      disabled ? '!bg-[#e9e9e9]' : ''
    } w-full rounded-[8px] border-[1px] border-[#CDCDCD] bg-white ${
      variant === 'compact' ? 'p-[14px_12px_6px_12px]' : 'p-[10px_12px]'
    } font-normal placeholder-[#12121299] ${className}`;

    const renderInput = () => {
      const commonProps = {
        onChange: handleChange,
        onFocus: handleFocus,
        onBlur: handleBlur,
        onKeyDown: handleKeyDown,
        disabled,
        value,
        className: inputClassName
      };

      if (autoGrow) {
        return (
          <textarea
            ref={textareaRef}
            rows={1}
            style={{ resize: 'none', overflow: 'hidden' }}
            {...commonProps}
          />
        );
      }

      return (
        <input
          ref={inputRef || ref}
          type={type}
          {...commonProps}
          placeholder={placeholder}
          data-testid={dataTestId ?? `input-${variant}`}
        />
      );
    };

    const renderLabel = () => (
      <label
        style={{
          top: mustMoveLabel ? '5px' : '12px',
          fontSize: mustMoveLabel ? '10px' : '16px'
        }}
        className="pointer-events-none absolute left-[13px] text-left text-placeholder transition-all duration-300 ease-in-out"
      >
        {label}
      </label>
    );

    const renderError = () =>
      error && (
        <Typography
          variant="caption"
          className={`text-error ${variant === 'compact' ? 'absolute left-1' : ''}`}
        >
          {error}
        </Typography>
      );

    if (variant === 'compact') {
      return (
        <div className="relative flex w-full flex-col">
          {renderInput()}
          {renderError()}
          {renderLabel()}
          {endAdornment && (
            <div className="absolute right-[12px] top-[50%] translate-y-[-50%]">
              {endAdornment}
            </div>
          )}
        </div>
      );
    }

    return (
      <div className="relative flex w-full flex-col">
        {label && <label className="mb-2">{label}</label>}
        {startAdornment && (
          <div className="absolute left-0 flex h-full items-center">
            {startAdornment}
          </div>
        )}
        <div className="flex h-full w-full flex-col items-center">
          {renderInput()}
          {renderError()}
          {endAdornment && (
            <div className="absolute right-0">{endAdornment}</div>
          )}
        </div>
      </div>
    );
  }
);
