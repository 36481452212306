import { Bell } from '@icons/index';

export const NotificationBell = () => {
  return (
    <div className="relative">
      <Bell />
      <div className="absolute right-0.5 top-0 z-10 flex h-2.5 w-2.5 animate-kora-pulse items-center justify-center rounded-full bg-secondary"></div>
    </div>
  );
};
