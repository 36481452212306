import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface PreviousPathContextType {
  previousPath: string | null;
}

const PreviousPathContext = createContext<PreviousPathContextType>({
  previousPath: null
});

export const usePreviousPath = () => useContext(PreviousPathContext);

interface PreviousPathProviderProps {
  children: React.ReactNode;
}

export const PreviousPathProvider: React.FC<PreviousPathProviderProps> = ({
  children
}) => {
  const [previousPath, setPreviousPath] = useState<string | null>(null);
  const location = useLocation();

  useEffect(() => {
    setPreviousPath(prev => {
      // Don't update previousPath if we're on the same path
      if (prev === location.pathname) return prev;
      return prev === null ? null : prev;
    });

    // Save current path as previous path when location changes
    return () => {
      setPreviousPath(location.pathname);
    };
  }, [location]);

  return (
    <PreviousPathContext.Provider value={{ previousPath }}>
      {children}
    </PreviousPathContext.Provider>
  );
};
