import { ProgressCircle } from '@components/profile';
import { VacancyDraft } from '@components/vacancies/VacancyDraft';
import { BackButton } from '@elements/BackButton';
import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Container } from '@elements/Container';
import { Divider } from '@elements/Divider';
import { MarkdownViewer } from '@elements/MarkdownViewer';
import { Typography } from '@elements/Typography';
import { Trash } from '@icons/index';
import { useBackground } from '@providers/BackgroundProvider';
import { useMarkdownContent } from '@services/use-markdown-content';
import { useDeleteVacancy } from '@services/vacancies/use-delete-vacancy';
import { useVacancy } from '@services/vacancies/use-vacancy';
import { Colors } from '@utils/ColorUtils';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ClipLoader } from 'react-spinners';

export const VacancyDetailPage = () => {
  useBackground(false);
  const { vacancyId } = useParams();
  const { vacancy } = useVacancy(vacancyId!);
  const { t } = useTranslation('vacancies');
  const { deleteVacancy } = useDeleteVacancy(vacancyId!);
  const navigate = useNavigate();

  const handleDelete = async () => {
    const isDeleted = await deleteVacancy();
    if (isDeleted) {
      navigate('/vacancies');
    }
  };

  const Body = () => {
    return (
      <>
        <div className="flex gap-8">
          <div className="flex w-full flex-col justify-between gap-8">
            <Typography
              variant="h1"
              className={`line-clamp-2 text-xl font-bold leading-[3rem] ${
                vacancy?.title ? '' : 'italic'
              }`}
              enableTooltip
              tooltipPlacement="top"
            >
              {vacancy?.title ?? t('draft')}
            </Typography>
            <Typography variant="h2" className="text-secondary">
              {vacancy?.employer?.companyName}
            </Typography>
          </div>
          {vacancy?.intakeProgress !== undefined && (
            <div className="flex items-center justify-end pl-3">
              <ProgressCircle
                hideButton
                percentage={
                  vacancy?.intakeProgress ? vacancy.intakeProgress * 100 : 0
                }
              />
            </div>
          )}
        </div>
        <Divider className="my-6 w-full" />
        <div className="mb-4 flex">
          <Link to={`/vacancies/${vacancyId}/chat`}>
            <Button variant="info">{t('edit')}</Button>
          </Link>
          <Button variant="void" onClick={handleDelete}>
            <Trash />
          </Button>
        </div>
        <Card>
          {vacancy?.summaryUrl && <VacancyMarkdown url={vacancy.summaryUrl} />}
          {vacancy?.intakeProgress === undefined ||
            (vacancy?.intakeProgress === 0 && (
              <Typography variant="h2" className="text-center text-lg">
                {t('no_information_available')}
              </Typography>
            ))}
          {vacancy && !vacancy.summaryUrl && (
            <VacancyDraft vacancy={vacancy} defaultOpen={true} hideTitle />
          )}
        </Card>
      </>
    );
  };

  return (
    <Container>
      {vacancy ? (
        <div className="grid grid-cols-[1fr_6fr_1fr] items-start pt-4">
          <BackButton />
          <div className="w-full px-6">
            <Body />
          </div>
        </div>
      ) : (
        <div className="flex h-full items-center justify-center">
          <ClipLoader color={Colors.primary} size={50} />
        </div>
      )}
    </Container>
  );
};

const VacancyMarkdown = ({ url }: { url: string }) => {
  const { markdownContent } = useMarkdownContent(url);
  return <MarkdownViewer content={markdownContent} />;
};
