import { Badge } from '@elements/Badge';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { ChevronRight } from '@icons/index';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

interface DashboardNotificationsProps {
  className?: string;
}
export const DashboardNotifications = ({
  className
}: DashboardNotificationsProps) => {
  const { t } = useTranslation('notifications');
  const { t: tVacancy } = useTranslation('vacancies');
  const { t: tRecruitmentFunnel } = useTranslation('recruitment-funnel');
  return (
    <Card className={`!p-6 ${className}`}>
      <Typography
        color="default"
        variant="h5"
        className="flex items-center gap-2 text-lg font-semibold"
      >
        <Badge color="secondary">3</Badge> {t('title')}
      </Typography>
      <div className="flex h-full flex-col justify-between">
        <div className="mt-3 flex flex-col gap-4 p-4">
          <ul className="list-disc space-y-6 pl-2">
            <NotificationItem
              to="/vacancies"
              content="Jan Bruinsma applied on Front-end developer vacancy"
              linkText={t('go_to', { name: tVacancy('vacancy') })}
            />
            <NotificationItem
              to="/vacancies"
              content="Mohammed de Vries Java developer hasn't received  an apply in 3 days"
              linkText={t('go_to', { name: tVacancy('vacancy') })}
            />
            <NotificationItem
              to="/recruitment-funnel"
              content="Ronaldo Bergwijn responded to interview request"
              linkText={t('go_to', {
                name: tRecruitmentFunnel('recruitment_funnel')
              })}
            />
            <NotificationItem
              to="/vacancies"
              content="Back-end developer (1)"
              linkText={t('go_to', { name: tVacancy('vacancy') })}
            />
          </ul>
        </div>
        <Typography color="error" variant="body1" className="mb-4 text-center">
          This is dummy data for now
        </Typography>
      </div>
    </Card>
  );
};

const NotificationItem = ({
  to,
  content,
  linkText
}: {
  to: string;
  content: string;
  linkText: string;
}) => {
  return (
    <li>
      <Link
        to={to}
        className="grid grid-cols-[1fr_auto] items-center justify-between gap-2 no-underline"
      >
        <Typography color="default" className="max-w-[80%] leading-6">
          {content}
        </Typography>
        <Typography
          color="secondary"
          className="flex items-center gap-1 font-semibold"
        >
          {linkText} <ChevronRight className="ml-1 h-3 w-3" />
        </Typography>
      </Link>
    </li>
  );
};
