import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Container } from '@elements/Container';
import { Input } from '@elements/Input';
import { MetaTags } from '@elements/MetaTags';
import { Typography } from '@elements/Typography';
import { useQueryParams } from '@hooks/use-query-params';
import { useAccountContext } from '@providers/UserContext';
import { useRefer } from '@services/use-refer';
import { Colors } from '@utils/ColorUtils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ClipLoader } from 'react-spinners';

export const ReferPage = () => {
  const { user } = useAccountContext();
  const { email: queryEmail } = useQueryParams();
  const [companyName, setCompanyName] = useState('');
  const [contactName, setContactName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const { t } = useTranslation('referrals');
  const { t: tLiterals } = useTranslation('literals');

  const { refer, canRefer, isLoading } = useRefer({
    companyName,
    contactName,
    contactEmail,
    senderEmail: user?.email ?? queryEmail
  });

  const handleRefer = () => {
    if (canRefer) {
      refer();
      setContactEmail('');
      setContactName('');
      setCompanyName('');
    }
  };

  return (
    <>
      <MetaTags title="Refer" />
      <Container className="mt-[-44px] flex h-full items-center justify-center">
        <Card className="flex w-[400px] flex-col gap-6 !p-6">
          <Typography variant="h1" className="text-center !text-xl">
            {t('refer_and_earn')}
          </Typography>
          <Typography
            variant="body1"
            className="text-center !text-sm !font-normal text-secondary"
          >
            {t('refer_company_description')}
          </Typography>
          <div className="flex flex-col gap-4">
            <Input
              placeholder={t('company_name')}
              value={companyName}
              onChange={e => setCompanyName(e.target.value)}
            />
            <Input
              placeholder={t('contact_name')}
              value={contactName}
              onChange={e => setContactName(e.target.value)}
            />
            <Input
              placeholder={t('contact_email')}
              value={contactEmail}
              onChange={e => setContactEmail(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <Button variant="info">{tLiterals('cancel')}</Button>
            <Button
              variant="primary"
              disabled={!canRefer}
              onClick={handleRefer}
            >
              {isLoading ? (
                <ClipLoader color={Colors.white} size={20} />
              ) : (
                t('refer_company_button')
              )}
            </Button>
          </div>
        </Card>
      </Container>
    </>
  );
};
