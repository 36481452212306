import { Colors } from '@utils/ColorUtils';

interface BadgeProps {
  children: React.ReactNode;
  color?: 'primary' | 'secondary' | 'success' | 'error';
}
export const Badge = ({ children, color = 'primary' }: BadgeProps) => {
  const hexColor = Colors[color];
  return (
    <div
      style={{ backgroundColor: hexColor + '33' }}
      className={`rounded-full text-${color} mr-1 px-4 py-0 font-bold`}
    >
      {children}
    </div>
  );
};
