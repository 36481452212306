import { lazy } from 'react';

import { QuickAction } from '@models/chatMessage';
import { Suspense } from 'react';
import ErrorQuickAction from './quick-actions/Error';

const loadComponents = async (
  quickActions: QuickAction[],
  messageId: string,
  sendMessage?: (message: string) => void
) => {
  const loadedComponents = await Promise.all(
    quickActions.map(async ({ name, data }) => {
      const quickActionName = name
        .split('_')
        .map(
          word =>
            word.substring(0, 1).toUpperCase() + word.substring(1).toLowerCase()
        )
        .join('');
      try {
        const Component = lazy(() =>
          import(`./quick-actions/${quickActionName}.tsx`).catch(() => ({
            default: () => <ErrorQuickAction />
          }))
        );
        return (
          <Suspense fallback={<ErrorQuickAction />}>
            <Component
              key={quickActionName}
              data={data}
              messageId={messageId}
              sendMessage={sendMessage}
            />
          </Suspense>
        );
      } catch (error) {
        console.error(
          `Component ${quickActionName} could not be loaded`,
          error
        );
        return null;
      }
    })
  );
  return loadedComponents.filter(Boolean);
};

export default loadComponents;
