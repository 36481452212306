import { Button } from '@elements/Button';
import { Colors } from '@utils/ColorUtils';
import { generateRandomString } from '@utils/TextUtils';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export interface ProgressCircleProps {
  percentage: number;
  size?: 'sm' | 'normal' | 'lg';
  hideButton?: boolean;
  color?: string;
}

export const ProgressCircle = ({
  percentage,
  size = 'normal',
  hideButton = false,
  color
}: ProgressCircleProps) => {
  const { t } = useTranslation('profile');
  const uniqueId = generateRandomString();

  const circleColor =
    color || (percentage === 100 ? Colors.primary : '#3B55F6');

  const sizeConfig = {
    sm: {
      size: '50px',
      strokeWidth: '4px',
      textSize: 'text-xs'
    },
    normal: {
      size: '100px',
      strokeWidth: '8px',
      textSize: 'text-xl'
    },
    lg: {
      size: '250px',
      strokeWidth: '20px',
      textSize: 'text-3xl'
    }
  };

  const config = sizeConfig[size];

  return (
    <>
      <style>
        {`
      .circular-progress-${uniqueId} {
        --size: ${config.size};
        --half-size: calc(var(--size) / 2);
        --stroke-width: ${config.strokeWidth};
        --radius: calc((var(--size) - var(--stroke-width)) / 2);
        --circumference: calc(var(--radius) * pi * 2);
        --dash: calc((var(--progress) * var(--circumference)) / 100);
        animation: progress-animation-${uniqueId} 1s linear 0s 1 forwards;
      }

      .circular-progress-${uniqueId} circle {
        cx: var(--half-size);
        cy: var(--half-size);
        r: var(--radius);
        stroke-width: var(--stroke-width);
        fill: none;
        stroke-linecap: round;
      }

      .circular-progress-${uniqueId} circle.bg {
        stroke: #ddd;
      }

      .circular-progress-${uniqueId} circle.fg {
        transform: rotate(-90deg);
        transform-origin: var(--half-size) var(--half-size);
        stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
        transition: stroke-dasharray 0.3s linear 0s;
        stroke: ${circleColor};
      }

      @property --progress {
        syntax: "<number>";
        inherits: false;
        initial-value: 0;
      }

      @keyframes progress-animation-${uniqueId} {
        from {
          --progress: 0;
        }
        to {
          --progress: ${percentage};
        }
      }
      `}
      </style>
      <div className="flex flex-col items-center justify-center">
        <div
          className={`relative ${size === 'lg' && 'mb-8'} flex items-center justify-center`}
        >
          <svg
            width={config.size}
            height={config.size}
            viewBox={`0 0 ${config.size} ${config.size}`}
            className={`circular-progress-${uniqueId}`}
          >
            <circle className="bg"></circle>
            <circle className="fg"></circle>
          </svg>
          <div className="absolute left-0 top-0 flex aspect-square h-full w-full items-center justify-center rounded-full">
            <span className={`font-bold text-black ${config.textSize}`}>
              {Math.round(percentage)}%
            </span>
          </div>
        </div>
        {!hideButton && size === 'lg' && (
          <div className="w-full text-center">
            <Link to="/intake">
              <Button variant={percentage === 100 ? 'info' : 'secondary'}>
                {percentage === 100
                  ? t('intake_completed')
                  : t('complete_intake')}
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
