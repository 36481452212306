import { Address } from '@models/address';
import { KoraApi } from '@services/api';
import { useMemo, useState } from 'react';

export const useAddressSearch = () => {
  const [value, setValue] = useState<string>();
  const [suggestions, setSuggestions] = useState<Address[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useMemo(async () => {
    if (!value) return;
    setIsLoading(true);
    const response = await KoraApi.get(`/address/search?query=${value}`);
    const result = response.data.map((address: any) =>
      Address.fromJson(address)
    );
    setSuggestions(result);
    setIsLoading(false);
  }, [value]);

  const memoized = useMemo(() => {
    return {
      setValue,
      clearSuggestions: () => setSuggestions([]),
      suggestions,
      isLoading
    };
  }, [suggestions, setValue, isLoading]);

  return memoized;
};
