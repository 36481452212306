import { useToast } from '@elements/Toast';
import { useAccountContext } from '@providers/UserContext';
import { KoraApi } from '@services/api';
import { isAxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useRefer = (props: {
  companyName?: string;
  contactName?: string;
  contactEmail?: string;
  senderEmail?: string;
}) => {
  const [canRefer, setCanRefer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('referrals');
  const { user } = useAccountContext();
  const { success, error: errorToast } = useToast();
  const type = user?.accountType ?? 'CANDIDATE';
  const refer = async () => {
    if (!canRefer) {
      return;
    }
    setIsLoading(true);
    try {
      await KoraApi.post('/referrals', {
        senderAccountId: user?.id,
        senderEmail: user?.email ?? props.senderEmail,
        recipientEmail: props.contactEmail,
        recipientName: props.contactName,
        companyName: props.companyName,
        type
      });
      success(t('referral_success'));
    } catch (error) {
      if (isAxiosError(error)) {
        errorToast(t(error.response?.data.detail));
      } else {
        errorToast('An error occurred');
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setCanRefer(
      (type != 'EMPLOYER' || !!props.companyName) &&
        !!props.contactName &&
        !!props.contactEmail
    );
  }, [props]);

  return { refer, canRefer, isLoading };
};
