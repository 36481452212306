import { Address } from './address';

export class Employer {
  constructor(
    public companyName?: string,
    public descriptionUrl?: string,
    public userDescription?: string,
    public industry?: string,
    public website?: string,
    public phone?: string,
    public firstName?: string,
    public lastName?: string,
    public intakeProgress?: number,
    public address: Address | null = null,
    public hasPendingJobs: boolean = false
  ) {}

  public static fromJson(json: any): Employer {
    return new Employer(
      json.companyName,
      json.descriptionUrl,
      json.userDescription,
      json.industry,
      json.website,
      json.phone,
      json.firstName,
      json.lastName,
      json.intakeProgress,
      json.address ? Address.fromJson(json.address) : null,
      json.hasPendingJobs
    );
  }
}
