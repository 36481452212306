import { Button } from '@elements/Button';
import { Card } from '@elements/Card';
import { Typography } from '@elements/Typography';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const DashboardRefer = () => {
  const { t } = useTranslation('referrals');
  return (
    <Card className="flex flex-col justify-between !p-6">
      <div>
        <Typography variant="h5" color="default" className="mb-6">
          {t('refer_and_earn')}
        </Typography>
        <Typography
          variant="body1"
          color="default"
          className="text-gray-500 mt-2 text-sm leading-6"
        >
          {t('refer_company_description')}
        </Typography>
      </div>
      <Link to="/refer" className="w-full">
        <Button variant="secondary" className="w-full">
          {t('refer_company_button')}
        </Button>
      </Link>
    </Card>
  );
};
